const AUTH_SECTIONS = {
  shopAdmin: {
    path: "u",
    requiredRoles: ["OWNER", "STAFF"],
  },
  marketplaceAdmin: {
    path: "superadmin",
    requiredRoles: ["ADMIN"],
  },
  public: {
    path: "*",
    requiredRoles: ["USER", "USER_PLUS", "PUBLIC"],
  },
};

export default AUTH_SECTIONS;

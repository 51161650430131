const CREATE_USER_MUTATION = `
  mutation CreateUser($input: [UsersInsertInput!]!) {
    insertUsers(objects: $input) {
      returning{
        __typename
        id
        username
        email
      }
    }
  }
`
export default CREATE_USER_MUTATION

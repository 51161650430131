import { initUrqlClient } from "next-urql";
import { getSession } from "next-auth/react";
import {
  fetchExchange,
  cacheExchange,
  ssrExchange,
  subscriptionExchange,
} from "urql";
import { authExchange } from "@urql/exchange-auth";
import { createClient as createWSClient } from "graphql-ws";
import { w3cwebsocket } from "websocket";

import getEnv from "../env/getEnv";

import isStorybook from "../../lib/env/isStorybook";
import isChromeExtension from "../../helpers/isChromeExtension";

import { getHeaders } from "./utils";
import authOptions from "../authOptions";

const isServer = typeof window === "undefined";

const getSessionUniversal = async ({ req = null, res = null }) => {
  if (isServer && req && res) {
    const { getServerSession } = await import("next-auth/next");
    return await getServerSession(req, res, authOptions(req, res));
  }
  return await getSession();
};

const initUrql = (
  authSection = "public",
  context = {},
  adminSecret = null,
  customHeaders,
  envVar = null,
) => {
  let subscriptionClient;
  if (!isStorybook()) {
    subscriptionClient = createWSClient({
      url: getEnv("NEXT_PUBLIC_SHOLDI_GRAPHQL_WS_ENDPOINT"),
      connectionParams: async () => {
        const session = !adminSecret ? await getSessionUniversal(context) : {};
        const headers = getHeaders(session, authSection, adminSecret);
        return { headers };
      },
      webSocketImpl: w3cwebsocket,
      // lazy: true,
    });
  }

  const cache = ssrExchange({ isClient: !isServer });
  const endpointVariable = isChromeExtension()
    ? "PLASMO_PUBLIC_SHOLDI_GRAPHQL_ENDPOINT"
    : "NEXT_PUBLIC_SHOLDI_GRAPHQL_ENDPOINT";
  const url = envVar ? envVar : getEnv(endpointVariable, null);
  const urqlClientOptions = {
    url,
    exchanges: [
      cacheExchange,
      authExchange(async (utils) => {
        let session = !adminSecret ? await getSessionUniversal(context) : {};
        return {
          addAuthToOperation(operation) {
            const headers = getHeaders(session, authSection, adminSecret);

            return utils.appendHeaders(operation, customHeaders ?? headers);
          },
          didAuthError(error) {
            return (
              error.message.includes("jwt") ||
              error.message.includes("allowed roles")
            );
          },
          async refreshAuth() {
            session = await getSessionUniversal(context);
          },
          willAuthError() {
            if (session) {
              const expires = session.expiresIn - 90;
              return Math.floor(Date.now() / 1000) >= expires;
            }
            return false;
          },
        };
      }),
      ...(!isStorybook()
        ? [
            subscriptionExchange({
              forwardSubscription: (operation) => ({
                subscribe: (sink) => ({
                  unsubscribe: subscriptionClient.subscribe(operation, sink),
                }),
              }),
            }),
          ]
        : []),
      cache,
      fetchExchange,
    ],
  };
  const client = initUrqlClient(urqlClientOptions, false);

  return { client, cache };
};

export default initUrql;

module.exports = (key, fallback = "") => {
  if (process?.env?.[key]) {
    return process.env[key];
  }

  if (typeof window === "undefined") {
    return fallback;
  }

  return window.env?.[key] ?? fallback;
};

import Credentials from "next-auth/providers/credentials";
import getEnv from "../lib/env/getEnv";
import initUrql from "../lib/urql/init-urql";
import CREATE_LOGIN_LOG_MUTATION from "@sholdi/graphql/mutations/fingerprint/createLoginLog";
import FacebookProvider from "next-auth/providers/facebook";
import GoogleProvider from "next-auth/providers/google";
import { providerRegisterLogin, shopLogin } from "../lib/fusionauth/utils";
import { parseJwt } from "../lib/sessionHelpers";
import { signOut } from "next-auth/react";

const authOptions = (req, res) => {
  let ip;

  if (typeof window === "undefined") {
    // Don't run IP extraction on the client-side
    // Extract user ip address
    if (req.headers["x-forwarded-for"]) {
      ip = req.headers["x-forwarded-for"].split(",")[0];
    } else if (req.headers["x-real-ip"]) {
      ip = req.connection.remoteAddress;
    } else {
      ip = req.connection.remoteAddress;
    }
  }

  return {
    providers: [
      Credentials({
        async authorize(credentials) {
          const url = `${getEnv(
            "SHOLDI_IDENTITY_URL",
            process.env.PLASMO_PUBLIC_SHOLDI_IDENTITY_URL,
          )}/api/login`;

          let body = {
            applicationId: getEnv("NEXT_PUBLIC_SHOLDI_IDENTITY_APP_ID"),
            loginId: credentials?.email,
            password: credentials?.password,
          };

          if (credentials.oneTimePassword) {
            body = { oneTimePassword: credentials.oneTimePassword };
          }

          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getEnv(
                "SHOLDI_IDENTITY_USER_SCOPE_API_KEY",
                process.env.PLASMO_PUBLIC_SHOLDI_IDENTITY_USER_SCOPE_API_KEY,
              )}`,
            },
            body: JSON.stringify(body),
          });

          const data = await response.json();

          if (response.ok && !data.exception) {
            // Log user signing
            const { client } = initUrql(
              "marketplaceAdmin",
              { req, res },
              getEnv("SHOLDI_HASURA_SECRET"),
            );

            await client
              .mutation(CREATE_LOGIN_LOG_MUTATION, {
                input: {
                  ipAddress: String(ip),
                  userId: data.user.id,
                },
              })
              .toPromise();

            return data;
          }
          return data.exception;
        },
      }),
      // Other providers like Facebook and Google
      FacebookProvider({
        clientId: getEnv("FACEBOOK_CLIENT_ID"),
        clientSecret: getEnv("FACEBOOK_CLIENT_SECRET"),
      }),
      GoogleProvider({
        clientId: getEnv("GOOGLE_CLIENT_ID"),
        clientSecret: getEnv("GOOGLE_CLIENT_SECRET"),
      }),
    ],

    callbacks: {
      async jwt({ token, user: newUser, account }) {
        let loginResponse = newUser;
        if (account && ["google", "facebook"].includes(account.provider)) {
          loginResponse = await providerRegisterLogin(account);
        }
        if (loginResponse) {
          const { refreshToken, token: accessToken, user } = loginResponse;
          const parsedToken = parseJwt(accessToken);
          const { exp } = parsedToken;
          return {
            refreshToken: refreshToken,
            accessToken,
            user: user,
            error: null,
            expiresIn: exp,
          };
        }

        const expires = token.expiresIn - 90;

        if (req.url.startsWith("/api/auth/session?shopAuth=")) {
          const shopId = req.query.shopAuth;

          if (token?.user.shopId !== shopId) {
            const shopToken = await shopLogin(shopId, token.accessToken);

            if (!shopToken?.error) {
              const { refreshToken, token: accessToken, user } = shopToken;
              const parsedToken = parseJwt(accessToken);
              const { exp } = parsedToken;

              return {
                refreshToken,
                accessToken,
                expiresIn: exp,
                error: null,
                user: {
                  ...user,
                  shopId: parsedToken["x-sholdi-shop-id"],
                },
              };
            }
            return { ...token, error: shopToken?.error };
          }
        }

        if (Math.floor(Date.now() / 1000) >= expires) {
          const url = `${getEnv(
            "SHOLDI_IDENTITY_URL",
            process.env.PLASMO_PUBLIC_SHOLDI_IDENTITY_URL,
          )}/api/jwt/refresh`;

          const refreshResponse = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.accessToken}`,
            },
            body: JSON.stringify({
              refreshToken: token.refreshToken,
            }),
          });

          const refreshData = await refreshResponse.json();

          if (refreshResponse.ok) {
            const { refreshToken, token: accessToken } = refreshData;
            const parsedToken = parseJwt(accessToken);
            const { exp } = parsedToken;
            return {
              error: null,
              ...token,
              refreshToken,
              accessToken,
              expiresIn: exp,
            };
          }
          await signOut();
          return { error: "Refresh token expired", ...token };
        }

        return token;
      },
      async session({ session, token }) {
        const { accessToken, user } = token;
        session.token = accessToken;
        session.user = user;
        session.expiresIn = token.expiresIn;
        return Promise.resolve(session);
      },
      redirect({ baseUrl }) {
        return baseUrl;
      },
    },
    secret: process.env.NEXTAUTH_SECRET,
    pages: {
      signIn: "/login",
      newUser: "/register",
    },
    // debug: true, // Remove in production
  };
};

export default authOptions;

import { addSeconds } from "date-fns/addSeconds";

import CREATE_USER_MUTATION from "@sholdi/graphql/mutations/users/userCreate";

import initUrql from "../urql/init-urql";

import { parseJwt } from "../sessionHelpers";

import getEnv from "../env/getEnv";

export const EXPIRES_IN = getEnv(
  "SHOLDI_IDENTITY_REFRESH_TOKEN_EXPIRE_IN",
  86400,
);

export async function refreshAccessToken(token) {
  const body = {
    refreshToken: token.refreshToken,
    token: token.accessToken,
  };

  try {
    const response = await fetch(
      `${getEnv("SHOLDI_IDENTITY_URL")}/api/jwt/refresh`,
      {
        headers: new Headers({
          "Content-Type": "application/json",
          "X-FusionAuth-TenantId": getEnv(
            "SHOLDI_IDENTITY_MARKETPLACE_TENANT_ID",
          ),
        }),
        method: "POST",
        body: JSON.stringify(body),
      },
    );

    const refreshedTokens = await response.json();

    if (!response.ok) {
      throw refreshedTokens;
    }

    return {
      ...token,
      accessToken: refreshedTokens.token,
      refreshToken: refreshedTokens.refreshToken ?? token.refreshToken,
      expiresIn: addSeconds(token.expiresIn, 3600).getTime(),
    };
  } catch (error) {
    return {
      ...token,
      error: "RefreshAccessTokenError",
    };
  }
}

function generateUsername(email) {
  const emailPrefix = email.split("@")[0];
  const randomNumber = Math.floor(Math.random() * 90000) + 10000; // 5-digit random number

  return `${emailPrefix}-${randomNumber}`;
}

export const providerRegisterLogin = async (account) => {
  const IDENTITY_PROVIDER =
    account.provider === "facebook"
      ? getEnv("SHOLDI_IDENTITY_FACEBOOK_IDP_ID")
      : getEnv("SHOLDI_IDENTITY_GOOGLE_IDP_ID");
  const KEY = account.provider === "facebook" ? "access_token" : "id_token";

  try {
    const res = await fetch(
      `${getEnv("SHOLDI_IDENTITY_URL")}/api/identity-provider/login`,
      {
        method: "POST",
        body: JSON.stringify({
          applicationId: getEnv("NEXT_PUBLIC_SHOLDI_IDENTITY_APP_ID"),
          identityProviderId: IDENTITY_PROVIDER,
          data: {
            token: account[KEY],
          },
        }),
        headers: new Headers({
          "X-FusionAuth-TenantId": getEnv(
            "SHOLDI_IDENTITY_MARKETPLACE_TENANT_ID",
          ),
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getEnv("SHOLDI_IDENTITY_USER_SCOPE_API_KEY"),
        }),
      },
    );

    const data = await res.json();

    const { user: identityUser } = data;

    if (identityUser) {
      const { client } = initUrql(
        "marketplaceAdmin",
        {},
        getEnv("SHOLDI_HASURA_SECRET"),
      );

      const { data: userData } = await client
        .query(
          /* GraphQL */ `
            query User($id: uuid!) {
              user: usersByPk(id: $id) {
                __typename
                id
              }
            }
          `,
          {
            id: identityUser.id,
          },
        )
        .toPromise();

      if (!userData || !userData?.user) {
        const { error } = await client
          .mutation(
            CREATE_USER_MUTATION,
            {
              input: [
                {
                  email: identityUser.email,
                  username: generateUsername(identityUser.email),
                  password: "",
                  id: identityUser.id,
                  avatar: identityUser?.imageUrl ?? null,
                },
              ],
            },
            {},
          )
          .toPromise();

        if (error) {
          throw new Error("Failed to create a user");
        }

        return data;
      }

      return data;
    }
    return null;
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line consistent-return
export async function shopLogin(shopId, token, ip, params) {
  const body = {
    applicationId: shopId,
    identityProviderId:
      params?.identityProviderId ??
      getEnv("SHOLDI_IDENTITY_MARKETPLACE_IDENTITY_PROVIDER_ID"),
    encodedJWT: token,
  };

  const url = `${params?.url ?? getEnv("SHOLDI_IDENTITY_URL")}/api/identity-provider/login`;
  try {
    const response = await fetch(url, {
      headers: new Headers({
        "Content-Type": "application/json",
        "X-FusionAuth-TenantId":
          params?.tenantId ?? getEnv("SHOLDI_IDENTITY_MARKETPLACE_TENANT_ID"),
        ...(ip ? { "X-Forwarded-For": ip } : {}),
      }),
      method: "POST",
      body: JSON.stringify(body),
    });

    if (![200, 212].includes(response.status)) {
      return {
        error: "SystemError",
      };
    }

    const shopToken = await response.json();
    if (shopToken["x-sholdi-shop-id" !== shopId]) {
      return {
        error: "Unauthorized",
      };
    }

    const parsedToken = parseJwt(shopToken.token);

    const additionalData = {
      shopId: parsedToken["x-sholdi-shop-id"] || null,
      allowedRoles: parsedToken["x-sholdi-allowed-roles"] || [],
      defaultRole: parsedToken["x-sholdi-default-role"] || null,
    };

    // return shopToken
    return {
      ...shopToken,
      ...additionalData,
      expiresIn: parsedToken.exp,
    };
  } catch (error) {
    console.log(error);
    return {
      token,
      error: "RefreshAccessTokenError",
    };
  }
}

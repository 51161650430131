import PropTypes from "prop-types";
import { SessionProvider } from "next-auth/react";

const CustomSessionProvider = ({ children, ...props }) => (
  <SessionProvider {...props}>{children}</SessionProvider>
);

CustomSessionProvider.propTypes = {
  children: PropTypes.node,
  session: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default CustomSessionProvider;

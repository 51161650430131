import { useEffect, useMemo } from 'react'

const useCatch = ({ asPath, query }) => {
  const path = useMemo(
    () =>
      encodeURI(
        // fails when you go to the search page with params
        // JSON.stringify({asPath,query})
        btoa(
          JSON.stringify({ asPath, query }).replace(/[\u00A0-\u2666]/g, function (c) {
            return '&#' + c.charCodeAt(0) + ';'
          })
        )
      ),
    [asPath, query]
  )

  const showCatch = useMemo(() => !asPath.includes('catch'), [asPath])

  useEffect(() => {
    let iframe

    const addIframe = () => {
      if (!iframe) {
        iframe = document.createElement('iframe')
        iframe.src = `/catch/${path}`
        iframe.style.opacity = '0'
        iframe.style.position = 'absolute'
        iframe.style.top = '0'
        iframe.style.left = '0'
        iframe.style.width = '1px'
        iframe.style.height = '1px'
        document.body.appendChild(iframe)
      }
    }

    if (typeof window !== 'undefined' && showCatch) {
      if (document?.readyState === 'complete') {
        window.addEventListener('scroll', addIframe, { once: true, passive: true })
      }
    }

    return () => {
      window.removeEventListener('scroll', addIframe)

      if (iframe) {
        document.body.removeChild(iframe)
      }
    }
  }, [path, showCatch])
}

export default useCatch
